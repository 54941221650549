// import Swiper bundle with all modules installed
import Swiper from 'swiper/swiper-bundle';

// import styles bundle
import 'swiper/swiper-bundle.min.css';

$(document).on("turbolinks:load", function () {
  $('#preview_images').empty();
  $('#input_images').on('change', function (event) {
    var files = event.target.files;
    for (let index = 0; index < files.length; index++) {
      const image = files[index];
      var reader = new FileReader();
      reader.onload = function (file) {
        var img = new Image();
        img.src = file.target.result;
        $('#preview_images').append(img);
      }
      reader.readAsDataURL(image);
    }
  });

  $('#input_images_sub').on('change', function (event) {
    $('#preview_images_sub').empty();
    var files = event.target.files;
    for (let index = 0; index < files.length; index++) {
      const image = files[index];
      var reader = new FileReader();
      reader.onload = function (file) {
        var img = new Image();
        img.src = file.target.result;
        $('#preview_images_sub').append(img);
      }
      reader.readAsDataURL(image);
    }
  });

  $('#input_avatar_image').on('change', function (event) {
    $('#preview_avatar_image').empty();
    var files = event.target.files;
    for (let index = 0; index < files.length; index++) {
      const image = files[index];
      var reader = new FileReader();
      reader.onload = function (file) {
        var img = new Image();
        img.src = file.target.result;
        img.className = "w-25";
        $('#preview_avatar_image').append(img);
      }
      reader.readAsDataURL(image);
    }
  });

  $('#user_postal_code').jpostal({
    postcode: [
      '#location_postal_code'
    ],
    address: {
      "#location_prefecture_id": "%3", // # 都道府県が入力される
      "#location_city": "%4%5", // # 市区町村と町域が入力される
      "#location_street": "%6%7" // # 大口事務所の番地と名称が入力される
    }
  });

  // Swiper
  var swiper = new Swiper(".mySwiper", {
    spaceBetween: 10,
    slidesPerView: 4,
    freeMode: true,
    watchSlidesProgress: true,
  });
  var swiper2 = new Swiper(".mySwiper2", {
    spaceBetween: 10,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    thumbs: {
      swiper: swiper,
    },
  });

  var isLoading = false;
  if ($('.infinite-scroll', this).length > 0) {
    $('.pagination').on("click", function () {
      var more_posts_url = $(this).data('link');
      if (!isLoading && more_posts_url) {
        isLoading = true;
        $.ajax({
          url: more_posts_url,
          dataType: "script",
          success: function () {
            isLoading = false
          },
          fail: function () {
            isLoading = false
          }
        });
      }
    });
  }

  $("#chat img").each(function () {
    if (this.complete) {
      $(this).trigger("load"); // すでに読み込まれている画像にも適用
    } else {
      $(this).on("load", function () {
        $("#chat").scrollTop($("#chat")[0].scrollHeight);
      });
    }
  });

  $("#sort_by").on("change", function () {
    var url = new URL(window.location.href);
    url.searchParams.set('sort_by', $(this).val());
    location.href = url;
  });

  const attachments = document.getElementById('attachments');

  if (attachments) {
    document.getElementById('attachments').addEventListener('change', function (event) {
      const previewsContainer = document.getElementById('attachment-previews');
      previewsContainer.innerHTML = '';
      Array.from(event.target.files).forEach(file => {
        const fileReader = new FileReader();
        fileReader.onload = function (e) {
          const previewElement = document.createElement('div');
          previewElement.classList.add('mb-2', 'd-inline-block', 'me-2');
          if (file.type.startsWith('image/')) {
            const img = document.createElement('img');
            img.src = e.target.result;
            img.style.maxWidth = '100px';
            img.style.maxHeight = '100px';
            previewElement.appendChild(img);
          } else {
            const fileName = document.createElement('p');
            fileName.textContent = file.name;
            previewElement.appendChild(fileName);
          }
          previewsContainer.appendChild(previewElement);
        };
        fileReader.readAsDataURL(file);
      });
    });
  }

  if (attachments) {
    attachments.addEventListener('change', function (event) {
      const avatarImg = document.querySelector('#avatar-uploaded img');
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const fileReader = new FileReader();
        fileReader.onload = function (e) {
          avatarImg.src = e.target.result;
        };
        fileReader.readAsDataURL(file);
      }
    });
  }

  const messageNotificationsSwitch = document.getElementById('messageNotificationsSwitch');
  if (messageNotificationsSwitch) {
    messageNotificationsSwitch.addEventListener('change', function (event) {
      const url = this.dataset.url;
      fetch(url, {
        method: 'PATCH',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
          'Accept': 'application/javascript'
        }
      })
        .then(response => response.text())
        .then(html => {
          const banner = document.createElement('div');
          const alert = new bootstrap.Alert(banner);
          banner.innerHTML = html;
          banner.className = 'position-absolute fade show';
          banner.style = 'width: 100%';
          document.querySelector('header').prepend(banner);
          setTimeout(() => {
            alert.close();
          }, 3000);
        });
    });
  }

  $('.alert').each(function () {
    setTimeout(() => {
      const alert = new bootstrap.Alert(this);
      alert.close();
    }, 5000); // 5 seconds
  });
});

