// File for your custom JavaScript
$(document).on("turbolinks:load", function () {
  // INITIALIZATION OF HEADER
  // =======================================================
  // new HSHeader('#header').init()

  // INITIALIZATION OF MEGA MENU
  // =======================================================
  new HSMegaMenu('.js-mega-menu', {
    desktop: {
      position: 'left'
    }
  })


  // INITIALIZATION OF BOOTSTRAP VALIDATION
  // =======================================================
  HSBsValidation.init('.js-validate')


  // INITIALIZATION OF BOOTSTRAP DROPDOWN
  // =======================================================
  HSBsDropdown.init()


  // INITIALIZATION OF GO TO
  // =======================================================
  new HSGoTo('.js-go-to')


  // INITIALIZATION OF TEXT ANIMATION (TYPING)
  // =======================================================
  HSCore.components.HSTyped.init('.js-typedjs')


  // INITIALIZATION OF TOGGLE PASSWORD
  // =======================================================
  new HSTogglePassword('.js-toggle-password')


  // INITIALIZATION OF SHOW ANIMATIONS
  // =======================================================
  new HSShowAnimation('.js-animation-link')

  // INITIALIZATION OF QUILLJS EDITOR
  // =======================================================
  HSCore.components.HSQuill.init('.js-quill')

  // INITIALIZATION OF FILE ATTACH
  // =======================================================
  new HSFileAttach('.js-file-attach')

  var current = location.pathname;
  $('.nav .nav-item a.nav-link').each(function () {
    var $this = $(this);
    // if the current path is like this link, make it active
    if ($this.attr('href') === current) {
      $this.addClass('active');
    }
  })

});
